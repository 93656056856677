.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*carousel*/
.carousel-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.carousel-caption{
  bottom: 60px;
}
.carousel-control-next span.visually-hidden,
.carousel-control-prev span.visually-hidden{
  display: none;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.451 25.451' fill='%23000'%3E%3Cpath d='M20.982.521v2.006L8.57 12.315c-.121.101-.195.251-.195.41s.074.311.195.41l12.412 9.79v2.005a.52.52 0 01-.297.469.519.519 0 01-.549-.061L4.664 13.136a.531.531 0 010-.82L20.136.113a.523.523 0 01.846.408z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
 background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.005 512.005' fill='%23000'%3E%3Cpath d='M388.418 240.923L153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165a21.275 21.275 0 0015.083 6.251 21.277 21.277 0 0015.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
 width: 45px;
 height: 45px;
}
.carousel-indicators .active{
  background-color: blue;
}
.carousel-indicators{
  bottom: 30px !important;
}
.carousel-indicators button + button{
  margin-left: 20px;
}
.carousel-indicators button{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
}
/*End carousel*/

/*Paralax*/
.react-parallax-background-children{
  position: relative;
}
/*End Paralax*/

/*Gallery*/
.thumb img {
  -webkit-filter: grayscale(0);
  filter: none;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
}
.thumb img:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.thumb {
  padding: 5px;
}
/*End Gallery*/

/*fancybox*/
.react-fancybox .thumbnail img {
  max-width: 255px !important;
  width: 100%;
}
.react-fancybox .box .image-box img{
  width: 100%;
}
.react-fancybox .close-btn{
  width: 25px !important;
  right: 0 !important;
}
/*End fancybox*/

/*style Tabs*/
.nav-link_link:hover{
  color: #282c34 !important;
}
/*End style Tabs*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
